import React from 'react'
import { Link } from 'gatsby'

class ContactUs extends React.Component {

    render() {
        return (
            <div className="contact-us">
                <h4>Jsem tu pro Vás.<br /> Neváhejte se zeptat.</h4>
                <div className="main-white-button">
                    <Link to="/kontakt" data-tracking-label="Sidebar_ContactUs">Pošlete mi dotaz</Link>
                </div>
            </div>
        )
    }
}

export default ContactUs

